// 2019 accessibility color palette

$primary-text-color: #424242;
$secondary-text-color: #757575;

$primary-blue: #1379bb;
$primary-blue-hover: #0b5999;
$primary-green: #69e2a8;
$primary-green-hover: #3ec999;

$error-color: #cc3d3d;
$error-hover-color: #a63232;

$border-color: #bcbfc3;
$page-background: rgb(246, 247, 247);

$surface-background: rgb(255, 255, 255);
