$proximanova-font-path: '../../node_modules/@cpg/assets/fonts/proximanova';

[class^='mui-'],
[class*='mui-'],
[class^='c42-'],
[class*='c42-'] {
  box-sizing: border-box;
}

@import 'node_modules/@c42/ui/lib/scss/resets/normalize';
@import 'node_modules/@c42/ui/lib/scss/resets/_typography-resets';
@import 'node_modules/@c42/ui/lib/scss/colors-aesthetics';
@import 'node_modules/@c42/ui/lib/scss/variables/colors';
@import 'node_modules/@c42/ui/lib/scss/variables/media-queries';
@import 'node_modules/@c42/ui/lib/scss/variables/spacing';
@import 'node_modules/@c42/ui/lib/scss/variables/custom-variables';
@import 'node_modules/@c42/ui/lib/scss/mixins/mixins';
@import 'node_modules/@c42/ui/lib/scss/fonts';
@import 'node_modules/@c42/ui/lib/scss/core/typography';
@import 'node_modules/@c42/ui/lib/scss/components/enhanced-textarea';
@import 'node_modules/@c42/ui/lib/scss/components/text-field';
@import 'node_modules/@c42/ui/lib/scss/components/drop-down-menu';
@import 'node_modules/@c42/ui/lib/scss/components/icon-button';
@import 'node_modules/@c42/ui/lib/scss/components/menu';
@import 'node_modules/@c42/ui/lib/scss/components/menu-item';
@import 'node_modules/@c42/ui/lib/scss/components/paper';
@import 'node_modules/@c42/ui/lib/scss/components/svg-icon';
@import 'node_modules/@c42/ui/lib/scss/components/c42-drop-down-menu';
@import 'node_modules/@c42/ui/lib/scss/components/tooltip';

@import 'colors';

* {
  font-family: 'ProximaNova';
}

.aafix {
  // render SVGs without blurry borders
  transform: rotate(0.01deg);
}

.brandings {
  display: block;
  text-align: center;
}

.no-msg {
  margin: 0 auto 61px;
  padding: 0;
}

[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak {
  display: none !important;
}

body {
  background: $page-background;
  font-family: 'ProximaNova' !important;
  padding-top: 1px; // prevent margin-collapse, which was permitting content margins to push body around
  padding-bottom: 50px;

  form {
    margin: auto;
    width: 70%;
  }

  a {
    color: $primary-blue;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
    &.btn:hover {
      text-decoration: none;
    }
  }

  .c42-login-wrapper {
    margin-top: 10%;

    .inline-pad {
      display: inline-block;
      padding: 0 5px;
    }

    .c42-login-box {
      width: min-content;
      margin: auto;
      min-height: 381px;
      margin: auto;

      .c42-login-body {
        padding-top: 40px;
        padding-bottom: 33px;
        background: $surface-background;
        box-shadow: 0 0 2px rgba(0, 0, 0, 0.15);

        .form-control-wrapper {
          display: block;
          margin: auto;

          .c42-drop-down-menu {
            width: 300px;
          }
          &.link {
            margin-bottom: 0;
          }
          // add a really long delay when Chrome changes the background to yellow when autofilling
          input:-webkit-autofill,
          input:-webkit-autofill:hover,
          input:-webkit-autofill:focus,
          input:-webkit-autofill:active {
            transition: page-background 5000s ease-in-out 5000s;
          }
        }
      }

      .challenge-message,
      .reset-password-message {
        text-align: center;
        margin-top: 30px;
      }

      .logo {
        width: 300px;
        img {
          width: 100%;
        }
      }

      .submit,
      .link {
        .btn {
          margin: 41px auto 27px;
          display: block;
          border-radius: 2px;
        }
        a,
        span {
          font-size: 14px;
          display: block;
          text-align: center;
        }
        span {
          font-size: 16px;
        }
      }

      .c42-redirect-text {
        margin-top: 8px;
        font-size: 12px;
        font-weight: 400;
        color: $secondary-text-color;
        text-align: center;
        &:first-of-type {
          margin-top: 0;
        }
      }

      .reset-password-form,
      .forgot-password-form {
        .submit .btn {
          width: 205px;
        }
      }

      .reset-password-form {
        .password-complexity {
          margin-top: 1em;
          ul {
            list-style: none;
          }
          p {
            &.complexity-header {
              font-weight: 600;
            }
          }
          li {
            padding-left: 23px;
            line-height: 1.5em;
            &.complexity-pass {
              background: url("data:image/svg+xml, %3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 0.5C3.875 0.5 0.5 3.875 0.5 8C0.5 12.125 3.875 15.5 8 15.5C12.125 15.5 15.5 12.125 15.5 8C15.5 3.875 12.125 0.5 8 0.5ZM6.5 11.75L2.75 8L3.8075 6.9425L6.5 9.6275L12.1925 3.935L13.25 5L6.5 11.75Z' fill='%23388E3C'/%3E%3C/svg%3E%0A")
                no-repeat left 0.15em;
            }
            &.complexity-initial {
              background: url("data:image/svg+xml, %3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 0.5C3.8525 0.5 0.5 3.8525 0.5 8C0.5 12.1475 3.8525 15.5 8 15.5C12.1475 15.5 15.5 12.1475 15.5 8C15.5 3.8525 12.1475 0.5 8 0.5ZM8 14C4.685 14 2 11.315 2 8C2 4.685 4.685 2 8 2C11.315 2 14 4.685 14 8C14 11.315 11.315 14 8 14Z' fill='%23757575'/%3E%3C/svg%3E%0A")
                no-repeat left 0.15em;
            }
            &.complexity-fail {
              background: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 0.5C12.1475 0.5 15.5 3.8525 15.5 8C15.5 12.1475 12.1475 15.5 8 15.5C3.8525 15.5 0.5 12.1475 0.5 8C0.5 3.8525 3.8525 0.5 8 0.5ZM10.6925 4.25L8 6.9425L5.3075 4.25L4.25 5.3075L6.9425 8L4.25 10.6925L5.3075 11.75L8 9.0575L10.6925 11.75L11.75 10.6925L9.0575 8L11.75 5.3075L10.6925 4.25Z' fill='%23D32F2F'/%3E%3C/svg%3E%0A")
                no-repeat left 0.15em;
              color: $error-color;
            }
          }
        }
      }

      .reset-totp-form {
        .success p {
          margin: revert;
        }
      }

      .c42-login-footer {
        width: 431px;
        margin: 20px 0;
        font-size: 12px;
        position: relative;
        div {
          display: inline-block;
          &.signup-link {
            position: absolute;
            left: 0;
          }
          &.forgot-password-link,
          &.logout-link {
            position: absolute;
            right: 0;
            text-align: right;
          }
        }
      }
    }

    .mui-text-field.mui-has-floating-labels {
      width: 100%;
    }

    .totp-tooltip {
      color: $secondary-text-color;
      font-size: 14px;
    }
  }
}

form[name='loginFrm'] {
  .totp-input {
    width: 180px;
    font-size: 14px;
    line-height: 18px;
    border-radius: 3px;
    border: 1px solid $border-color;
    padding: 5px;
    margin-left: 24px;

    &.errored {
      border-color: $error-color;
    }
  }

  .totp-verify-error {
    color: $error-color;
  }
}

/**
 Login - TOTP Config Form
 */
form[name='loginFrm'].step-totp-config {
  padding-top: 25px;

  .totp-config-headline {
    font-size: 16px;
    font-weight: bold;
  }

  .totp-config-description {
    font-size: 14px;
    padding-top: 6px;
  }

  .totp-config-steps-list {
    margin: 0;
    list-style-type: none;
    & > li {
      counter-increment: step-counter;
      padding-top: 16px;

      &::before {
        content: counter(step-counter);
        display: inline-block;
        color: $primary-blue;
        width: 30px;
        height: 30px;
        line-height: 32px;
        text-align: center;
        border: 1px solid $primary-blue;
        border-radius: 30px;
        font-size: 16px;
      }

      .totp-config-headline {
        display: inline-block;
        padding-left: 10px;
      }

      .totp-config-step-body {
        font-size: 14px;
        margin-left: 16px;
        width: 580px;
        > * {
          max-width: 70%;
        }

        &.bordered {
          border-left: 1px solid $border-color;
        }

        & > *:not(input) {
          padding-left: 24px;
        }

        .totp-config-list {
          display: inline;
          text-decoration: none;
          &::before {
            content: ' \2014  ';
          }
          li {
            display: inline;
            &:not(:last-of-type)::after {
              content: ' \007C  ';
            }
          }
        }
      }
    }
  }

  .totp-secret-input {
    border: 0;
    font-weight: bold;
    font-size: 13px;
    margin-left: -10px;
    padding: 5px 10px;
    width: 300px;
    resize: none;
  }

  .totp-qr-code {
    padding-top: 10px;
    width: 192px;
  }

  .totp-secret-verification {
    .totp-input-feedback {
      line-height: 22px;
      display: flex;
      justify-items: center;
      flex-direction: column;

      small {
        font-size: 12px;
        padding-top: 4px;
        color: $secondary-text-color;
      }
    }
  }

  .totp-account-add {
    p {
      padding-bottom: 8px;
    }
    dd {
      cursor: copy;
      text-decoration: underline;
    }
  }
}

/**
 Login - TOTP Code Form
 */
form[name='loginFrm'].step-totp-code {
  .form-control-wrapper {
    text-align: center;

    &.submit .btn {
      margin-top: 30px;
    }
  }

  .totp-code-description {
    font-size: 14px;
    padding-top: 30px;

    .totp-info-icon {
      cursor: pointer;
      display: inline-block;
      vertical-align: text-bottom;

      .c42-svg-icon {
        height: 16px;
        min-height: 16px;
        color: $primary-blue;
        vertical-align: bottom;
      }
    }
  }

  .c42-tooltip {
    width: 245px;
    padding: 10px;
    text-align: left;
    margin-left: -10px;
  }

  .totp-input-wrapper {
    padding-top: 10px;
  }

  .totp-input {
    width: 105px;
    text-align: center;
  }
}

/**
 Login - TOTP Invalid Form
 */
form[name='loginFrm'].step-totp-invalid {
  .form-control-wrapper {
    text-align: center;

    &.submit .btn {
      margin-top: 30px;
    }
  }

  .totp-code-headline {
    margin: 0;
    font-size: 14px;
    font-weight: bold;
    padding-top: 30px;
  }

  .totp-code-description {
    display: inline-block;
    font-size: 14px;
    width: 275px;
  }
}

/**
 Login - TOTP Storage Node Warning
 */
.step-totp-sn-warning {
  width: 80%;
  margin: auto;
  font-size: 14px;
  line-height: 1.2rem;
  text-align: center;

  .totp-sn-warning-body {
    padding-top: 27px;
  }

  .totp-sn-warning-link {
    padding-top: 1rem;
  }
}

/**
 Login - Username mismatch warning
 */
.step-username-mismatch {
  width: 80%;
  margin: auto;
  font-size: 14px;
  line-height: 1.2rem;
  text-align: left;

  p {
    margin: revert;
  }
}

/**
 Login - Agent Login Error
 */
.step-agent-login-error {
  width: 80%;
  margin: auto;
  font-size: 14px;
  line-height: 1.2rem;
  text-align: center;

  p {
    margin: revert;
  }

  .error-text {
    color: $secondary-text-color;
  }
}

// === overrides for the setup form (aka the MLK form, only seen upon a fresh install) === //

form[name='setupFrm'] {
  // display some text with an embedded link as a single line
  .form-control-wrapper.link {
    text-align: center;
    font-size: 14px;

    a {
      display: inline-block;
      clear: none;
    }
  }
} // end form[name="setupFrm"]

.btn.btn-flat.btn-default {
  &[disabled],
  &:hover[disabled] {
    opacity: 0.3;
    color: white !important;
    background: $primary-blue;
  }

  &.cpg-blue {
    background: $primary-blue;
    font-family: 'ProximaNovaSemi';
    &:hover {
      background: $primary-blue-hover;
    }
  }

  &.c42-button {
    border-width: 0;
    text-transform: none;
    color: white;
    width: 159px;
    height: 36px;
    font-size: 14px;
  }

  &.cpg-button {
    border-width: 0;
    text-transform: none;
    color: white;
    height: 36px;
    padding: 0 1em;
    font-size: 14px;
  }
}

.form-control-wrapper .material-input:before {
  background-color: $primary-blue;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  opacity: 0; /* make things invisible upon start */
  -webkit-animation: fadeIn ease-in 1; /* call our keyframe named fadeIn, use animattion ease-in and repeat it only 1 time */
  -moz-animation: fadeIn ease-in 1;
  animation: fadeIn ease-in 1;

  -webkit-animation-fill-mode: forwards; /* this makes sure that after animation is done we remain at the last keyframe value (opacity: 1)*/
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  -webkit-animation-duration: 0.2s;
  -moz-animation-duration: 0.2s;
  animation-duration: 0.2s;
}

.fade-in.one {
  -webkit-animation-delay: 0.1s;
  -moz-animation-delay: 0.1s;
  animation-delay: 0.1s;
}

@mixin notification() {
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  padding: 19px 30px;
  text-align: center;
  font-size: 16px;
  width: auto;
  margin: 25px auto 32px;
}

.error {
  @include notification();
  border-color: $error-color;
  color: $error-color;
}

.success {
  @include notification();
  border-color: $primary-green;
  color: $primary-green;
}

.logo-text {
  white-space: pre;
  text-align: center;
  padding-bottom: 20px;
  font-size: 1.3em;
  font-weight: 500;
  &:empty {
    display: none;
  }
}

// This is a fix for mui-text-field-underline (used in this case for the username field) not being left-aligned in MS EDGE browsers
form > .form-control-wrapper.mui-is-disabled > hr.mui-text-field-underline::after {
  left: 0;
}

.waiting-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 10rem;
}
